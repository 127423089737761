'use strict';

import $ from "jquery";
import moment from 'moment';
import 'moment/min/locales.min';
// moment.locale('fr');


$.fn.TimeLine = function (config) {
    this.$timeline = this;

    this.configDefault = {
        lang: 'fr',
        nbMonth : 8,
        style : {
            backgroundColor : '#abb9c04a',
            btn : {
                backgroundColor : '#fff',
                color : '#000',
            },
            btnHover : {
                backgroundColor : '#5cc0c1',
                color : '#fff',
            }
        }
    };

    /**
     * Créer le html de la frise de date.
     */
    this.createTimeline = function () {
        var ulTimeline = document.createElement('ul');

        this.$ulTimeline = $(ulTimeline);

        this.generateLiDateTimeLine(this.dateStart);

        ulTimeline.style.margin = 0;

        this.$timeline.css('padding', '1em');
        this.$timeline.css('text-align', 'center');
        this.$timeline.css('margin-bottom', '1em');
        this.$timeline.css('background-color', this.config.style.backgroundColor);

        this.$timeline.html(ulTimeline);

        this.$blockContent = $('<div id="contentTimeline"></div>');
        this.$timeline.after(this.$blockContent);
    };

    /**
     * Générer la liste des dates par mois.
     * @param date
     */
    this.generateLiDateTimeLine = function (date) {
        var timelineObject = this.$timeline;
        this.dateStart = date.clone();
        this.$ulTimeline.html('');

        // Ajout du bouton précédent.
        var liPrev = document.createElement('li');
        var iconPrev = document.createElement('i');
        iconPrev.className = 'fas fa-backward';

        liPrev.id = 'btnPrevTimeline';
        liPrev.style.display = 'inline-block';
        liPrev.style.padding = '0.5em 1em';
        liPrev.style.border = '1px solid #66666694';
        liPrev.style.marginRight = "1em";
        liPrev.style.borderRadius = "0.5em";

        $.each(this.config.style.btn, function (i, item) {
            liPrev.style[i] = item;
        });

        liPrev.appendChild(iconPrev);
        this.$ulTimeline.append(liPrev);

        for (var i = this.config.nbMonth; i > 0; i--)
        {
            var li = document.createElement('li');
            var formatTextDate = date.format('MMMM YYYY').substr(0,1).toUpperCase() + date.format('MMMM YYYY').substr(1);
            var textDate = document.createTextNode(formatTextDate);

            li.className = "liDate";
            li.style.display = 'inline-block';
            li.style.padding = '0.5em 1em';
            li.style.border = '1px solid #66666694';

            $.each(this.config.style.btn, function (i, item) {
                li.style[i] = item;
            });

            if (i !== 1) {
                li.style.borderRight = '0';
            }

            li.appendChild(textDate);

            this.$ulTimeline.append(li);
            date = date.add(1, 'M'); // On incrémente +1 mois.
        }

        // Ajout du bouton suivant.
        var liNext = document.createElement('li');
        var iconNext = document.createElement('i');
        iconNext.className = 'fas fa-forward';

        liNext.id = 'btnNextTimeline';
        liNext.style.display = 'inline-block';
        liNext.style.padding = '0.5em 1em';
        liNext.style.border = '1px solid #66666694';
        liNext.style.marginLeft = "1em";
        liNext.style.borderRadius = "0.5em";

        $.each(this.config.style.btn, function (i, item) {
            liNext.style[i] = item;
        });

        liNext.appendChild(iconNext);

        this.$ulTimeline.append(liNext);

        // On initialise le style sur le hover.
        this.$ulTimeline.find('li').bind('mouseover', function(){
            this.style.cursor = 'pointer';
            var elem = this;

            $.each(timelineObject.config.style.btnHover, function (i, item) {
                elem.style[i] = item;
            });
        });

        this.$ulTimeline.find('li').bind('mouseout', function () {
            var elem = this;

            if (!elem.classList.contains('active')) {
                $.each(timelineObject.config.style.btn, function (i, item) {
                    elem.style[i] = item;
                });
            }
        });

        this.loadEvent();
    };

    this.loadEvent = function () {
        var timelineObject = this.$timeline;

        $(function () {
            console.warn("init event timeline !");

            timelineObject.$timeline.find('#btnPrevTimeline').on('click', function () {
                timelineObject.dateStart.subtract(1, 'M');

                timelineObject.setDateTarget(timelineObject.dateStart);
                timelineObject.generateLiDateTimeLine(timelineObject.dateStart);
                timelineObject.activeBtnStyle(timelineObject.$timeline.find('li.liDate').get(0));
                timelineObject.loadContent(timelineObject.dateStart);
            });

            timelineObject.$timeline.find('#btnNextTimeline').on('click', function () {
                timelineObject.dateStart.add(1, 'M');
                
                timelineObject.setDateTarget(timelineObject.dateStart);
                timelineObject.generateLiDateTimeLine(timelineObject.dateStart);
                timelineObject.activeBtnStyle(timelineObject.$timeline.find('li.liDate').get(0));
                timelineObject.loadContent(timelineObject.dateStart);
            });

            timelineObject.$timeline.find('li.liDate').on('click', function () {
                var dateSelect = moment(this.innerHTML, 'MMMM YYYY');

                timelineObject.activeBtnStyle(this);
                timelineObject.setDateTarget(dateSelect);
                timelineObject.loadContent(dateSelect);
            })
        })
    };

    /**
     * Modifie la valeur de dateTarget.
     * @param date
     */
    this.setDateTarget = function (date) {
        if (date) {
            this.dateTarget = date;
        }
    };

    /**
     * Met en forme le style du bouton lors d'un click sur celui-ci.
     * @param li
     */
    this.activeBtnStyle = function (li) {
        if (li) {
            var timelineObject = this.$timeline;

            this.$timeline.find('li.active').each(function () {
                var elem = this;

                elem.classList.remove('active');

                $.each(timelineObject.config.style.btn, function (i, item) {
                    elem.style[i] = item;
                });
            });

            li.classList.add('active');

            $.each(timelineObject.config.style.btnHover, function (i, item) {
                li.style[i] = item;
            });
        } else {
            console.error('error activeBtnStyle : li not found !');
        }
    };

    /**
     * Charge le contenu adéquate à la date sélectionnée.
     * @param date
     */
    this.loadContent = function (date) {
        this.$blockContent.html('<div class="text-center"><img class="rounded" style="height: 3em" src="/img/spinner.gif" alt="spinner"></div>');

        if (this.config.eventUpdateDate)
        {
            this.config.eventUpdateDate(date, this.$blockContent);
        } else {
           console.warn('Aucune function "eventUpdateDate(date, content)" n\'a été déclarée.')
        }
    };

    /**
     * On initialise les données. :)
     */
    this.init = function () {
        this.config = config !== undefined ? {
            lang: config.lang !== undefined ? config.lang : this.configDefault.lang,
            nbMonth : config.nbMonth !== undefined ? config.nbMonth : this.configDefault.nbMonth,
            eventUpdateDate: config.eventUpdateDate !== undefined ? config.eventUpdateDate : null,
            style : config.style !== undefined ? {
                backgroundColor : config.style.backgroundColor !== undefined ? config.style.backgroundColor : this.configDefault.style.backgroundColor,
                btn : config.style.btn !== undefined ? config.style.btn : this.configDefault.style.btn,
                btnHover : config.style.btnHover !== undefined ? config.style.btnHover : this.configDefault.style.btnHover,
            } : this.configDefault.style
        } : this.configDefault;

        moment.locale(this.config.lang);

        this.dateTarget = moment('01/' + (moment().month() + 1) + '/' + moment().year(), 'DD/MM/YYYY');
        this.dateStart = this.dateTarget.clone();

        this.createTimeline();

        this.activeBtnStyle(this.$timeline.find('li.liDate').get(0)); // On active la première date par défaut.
        this.loadContent(this.dateTarget);
    };

    if (this.$timeline.length)
    {
        this.init();
    } else {
        console.error('Init fail of TimeLine. (element not found)');
    }
};
