window.TableActions = function (value, row, index) {
    return [
        // '<a class="btn btn-default btn-sm" href="' + row.url.show + '" title="Voir">',
        // '<i class="fa fa-eye"></i> Voir',
        // '</a> ',
        '<a class="btn btn-success btn-sm" href="' + row.url.edit + '" title="Éditer">',
        '<i class="fas fa-pencil-alt"></i> Éditer',
        '</a> ',
        row.url.delete,
    ].join('');
};

window.TableCandidatActions = function (value, row, index) {
    return [
        row.url.active,
        '<a class="btn btn-success btn-sm" href="' + row.url.edit + '" title="Éditer">',
        '<i class="fas fa-pencil-alt"></i> Éditer',
        '</a> ',
        row.url.delete,
    ].join('');
};

window.TableActionsBusiness = function (value, row, index) {
    var arrayBtn = [];

    if (row.url.edit)
    {
        arrayBtn.push(
            '<a class="btn btn-success btn-sm" href="' + row.url.edit + '" title="Éditer">',
            '<i class="fas fa-pencil-alt"></i> Éditer',
            '</a> '
        );
    } else if (row.url.show) {
        arrayBtn.push(
            '<a class="btn btn-default btn-sm" href="' + row.url.show + '" title="Voir">',
            '<i class="fa fa-eye"></i> Voir',
            '</a> ');
    }

    arrayBtn.push(row.url.delete);

    return arrayBtn.join('');
};

window.TableActionsDeleteOnly = function (value, row, index) {
    return [
        row.url.delete,
    ].join('');
};

window.TableActionsBusinessEngineers = function (value, row, index) {
    return [
        '<button class="btn btn-default btn_show_modal" data-toggle="modal" data-target="#formModal" id="businessEngineers" data-id="' + row.id + '">',
        '<i class="fas fa-pencil-alt"></i> Voir / Editer',
        '</button> ',
    ].join('');
};

window.TableActionsSignatureCauserie = function (value, row, index) {
    return [
        '<a class="btn btn-default" href="'+ row.url.edit +'" target="_blank">',
        '<i class="fas fa-pencil-alt"></i> Editer le collaborateur',
        '</a> ',
    ].join('');
};

window.TableActionsRemonteeBusiness = function (value, row, index) {
    var arrayBtn = [
        '<button class="btn btn-default btn_show_modal" data-toggle="modal" data-target="#formModal" id="remonteeBusiness" data-id="' + row.id + '">',
        '<i class="fas fa-pencil-alt"></i> Voir / Editer',
        '</button> '
    ];

    if (row.url.delete) {
        arrayBtn.push(row.url.delete);
    }

    return arrayBtn.join('');
};

window.TableActionsAudit = function (value, row, index) {
    return [
        '<button class="btn btn-default btn_show_modal" data-toggle="modal" data-target="#formModal" id="audit" data-id="' + row.id + '">',
        '<i class="fas fa-pencil-alt"></i> Voir / Editer',
        '</button> ',
        row.url.delete,
    ].join('');
};

window.TableActionsVisitBusiness = function (value, row, index) {
    return [
        '<button class="btn btn-default btn_show_modal" data-toggle="modal" data-target="#formModal" id="visitBusiness" data-id="' + row.id + '">',
        '<i class="fas fa-pencil-alt"></i> Voir / Éditer',
        '</button> ',
    ].join('');
};

window.TableActionsVisitBusinessSecurite = function (value, row, index) {
    return [
        '<button class="btn btn-default btn_show_modal" data-toggle="modal" data-target="#formModal" id="visitBusinessSecurite" data-id="' + row.id + '">',
        '<i class="fas fa-pencil-alt"></i> Voir',
        '</button> ',
    ].join('');
};

window.TableActionsSuivi = function (value, row, index) {
    return [
        '<a class="btn btn-success btn-sm" href="' + row.url.suivi + '" title="Suivi">',
        '<i class="fas fa-edit"></i> Suivi',
        '</a> ',
    ].join('');
};


window.TableActionsContacts = function (value, row, index) {
    return [
        row.url.user,
        '<a class="btn btn-success btn-sm" href="' + row.url.edit + '" title="Éditer">',
        '<i class="fas fa-pencil-alt"></i> Éditer',
        '</a> ',
        row.url.delete,
    ].join('');
};

window.TableActionsCauserie = function (value, row, index) {
    return [
        '<button class="btn btn-default btn_show_modal" data-toggle="modal" data-target="#formModalSignatures" id="causerieSignature" data-id="' + row.id + '">',
        '<i class="fas fa-eye"></i> Voir les signatures',
        '</button> ',
        '<button class="btn btn-default btn_show_modal" data-toggle="modal" data-target="#formModal" id="causerie" data-id="' + row.id + '">',
        '<i class="fas fa-pencil-alt"></i> Éditer',
        '</button> ',
        row.url.delete,
    ].join('');
};

window.TableActionsFormationSecurity = function (value, row, index) {
    return [
        '<button class="btn btn-default btn_show_modal" data-toggle="modal" data-target="#formModalSignatures" id="causerieSignature" data-id="' + row.causerieId + '">',
        '<i class="fas fa-eye"></i> Voir les participants',
        '</button> ',
        '<button class="btn btn-default btn_show_modal" data-toggle="modal" data-target="#formModal" id="formationSecurity" data-id="' + row.id + '">',
        '<i class="fas fa-pencil-alt"></i> Éditer',
        '</button> ',
        row.url.delete,
    ].join('');
};

window.TableActionsCheckPdps = function (value, row, index) {
    return [
        '<button class="btn btn-default btn_show_modal" data-toggle="modal" data-target="#formModalCheckPdps" id="pdpCheckPdps" data-id="' + row.id + '">',
        '<i class="fas fa-eye"></i> Voir les prises de connaissances',
        '</button> '
    ].join('');
};

window.TableActionsRexCollabArchived = function (value, row, index) {
    return [
        '<button class="btn btn-default btn_show_modal" data-toggle="modal" data-target="#formModal" id="rex" data-id="' + row.businessId + '">',
        '<i class="fas fa-eye"></i> Voir le REX',
        '</button> '
    ].join('');
};

window.TableActionsNeed = function (value, row, index) {
    return [
        '<button class="btn btn-default btn_show_modal" data-toggle="modal" data-target="#formModal" id="need" data-id="' + row.id + '">',
        '<i class="fas fa-pencil-alt"></i>',
        '</button> ',
        row.url.delete,
    ].join('');
};
