'use strict';

const ModalEditObject = function (config) {
    this.$btnEditItem = config.btnEditItem ?? $('.btn_edit_item');
    this.$btnShowModal = $('.btn_show_modal');
    this.$modalForm = $(this.$btnEditItem.attr('data-target'));
    this.$selectContact =  $('#business_contact');
    this.$selectShowModal = $('select.select_show_modal');
    this.contacts = [];

    this.init();
    this.showDefault();
    this.showBySelect();
};

ModalEditObject.prototype.init = function() {
    var modalEdit = this;
    //this.$selectShowModal.unbind('change');
    this.$selectContact.unbind('change');

    this.$selectShowModal.each(function () {
        if ($(this).val() !== '') {
            modalEdit.showButtonEdit($(this));
        } else {
            $('#bill_collaborater').html('');
            $('#bill_business').html('');
            $('#bill_collaborater').selectpicker('refresh');
            $('#bill_business').selectpicker('refresh');
            $('#btn_create_site').show();
            $('#btn_create_customer').show();
        }
    });

    $(document).on('hidden.bs.modal', '.modal',
        () => $('.modal:visible').length && $(document.body).addClass('modal-open')
    );

    this.$selectShowModal.on('change', function () {
        modalEdit.showButtonEdit($(this));
    });

    this.$selectContact.on('change', function () {
        var $selectContact = $(this);
        var idContact = parseInt($(this).val());

        modalEdit.changeValueFormContact($selectContact, idContact);
    });
};

/**
 * Affiche le formulaire d'un contact.
 *
 * @param $btnEdit
 * @param idCustomer
 * @param $selectContact
 */
ModalEditObject.prototype.showFormContact = function ($btnEdit, idCustomer, $selectContact) {
    if ($btnEdit)
    {
        $btnEdit.show();
    }

    $selectContact.html('');

    if (idCustomer !== '') {
        $('.bloc_form-contact').show();
        this.getContactCustomer(idCustomer, $selectContact);
    }
};

ModalEditObject.prototype.changeValueFormContact = function($selectContact, idContact)
{
    var modalEdit = this;

    $selectContact.parents('.bloc_form-contact').find('.mailContact').val('');
    $selectContact.parents('.bloc_form-contact').find('.phoneContact').val('');
    $selectContact.parents('.bloc_form-contact').find('.functionContact').val('');

    modalEdit.contacts.forEach(function (contact) {
        if(contact.id == idContact) {
            $selectContact.parents('.bloc_form-contact').find('.mailContact').val(contact.mail);
            $selectContact.parents('.bloc_form-contact').find('.phoneContact').val(contact.phone);
            $selectContact.parents('.bloc_form-contact').find('.functionContact').val(contact.fonction);
        }
    });
};

/**
 * Editer un object via un bouton simplement.
 */
ModalEditObject.prototype.showDefault = function()
{
    var modalEdit = this;
    this.$btnShowModal.unbind('click');

    this.$btnShowModal.on('click', function (e) {
        e.preventDefault();

        var idElem = $(this).attr('id');
        var id = $(this).data('id');
        var dataAjax = modalEdit.getInfoAjax(idElem, id, e.target);
        var params = dataAjax.params;

        modalEdit.$modalForm = $($(this).attr('data-target'));

        modalEdit.ajax(dataAjax, params, function () {
            var formSerialize = dataAjax.idForm ? modalEdit.$modalForm.find('#'+dataAjax.idForm).serialize() : [];

            $.post(dataAjax.url, formSerialize, function(response) {
                if (!response.error) {
                    modalEdit.$modalForm.modal('hide');

                    pushToast('success', response.message);

                    modalEdit.$btnShowModal.parents('#table').bootstrapTable('refresh', {url: $('#table').data('urlajax')});

                    if (dataAjax.callback) {
                        dataAjax.callback(response);
                    }
                } else {
                    response.error.forEach(function (index) {
                        pushToast('error', index);
                    });
                }
            });
        });
    });
};

/**
 * Editer un object via une select.
 */
ModalEditObject.prototype.showBySelect = function()
{
    var modalEdit = this;

    // this.$btnEditItem.unbind('click');
    this.$btnEditItem.on('click', function (e) {
        e.preventDefault();

        var $select = $(this).parents('.row:first').find('select');
        var idSelect = $select.attr('id');
        var id = $select.val();
        var parentSelect = $(e.target).parents('.row:first').find('select');

        var dataAjax = modalEdit.getInfoAjax(idSelect, id, e.target);

        modalEdit.ajax(dataAjax, { id : id }, function () {
            var formSerialize = modalEdit.$modalForm.find('#'+dataAjax.idForm).serialize();

            $.post(dataAjax.url, formSerialize, function(response) {
                if (!response.error) {
                    modalEdit.$modalForm.modal('hide');
                    pushToast('success', response.message);

                    switch (idSelect) {
                        case undefined:
                        case 'business_collaborater':
                            $select =  $('#business_collaborater');
                            $select.append('<option value="'+response.id+'" selected="selected">'+response.name+'</option>');
                            break;

                        case 'business_customer':
                            if (response.name && response.id) {
                                $select =  $('#business_customer');
                                $select.append('<option value="' + response.id + '" selected="selected">'+response.name+'</option>');
                                $('#btn_create_customer').hide();

                                id = response.id;

                                modalEdit.showFormContact($('#btn_edit_customer'), id, $('#business_contact'));
                            }

                            response.contactArray.forEach(function (contact) {
                                modalEdit.$selectContact.append('<option value="' + contact.id + '" selected="selected">' + contact.name + '</option>');
                            });

                            if (response.contactArray.length) {
                                modalEdit.changeValueFormContact(modalEdit.$selectContact, response.contactArray[response.contactArray.length - 1].id);
                            }
                            break;

                        case 'need_customer':
                            if (response.name && response.id) {
                                $select = parentSelect;
                                $select.append('<option value="' + response.id + '" selected="selected">'+response.name+'</option>');
                                $('#btn_create_customer').hide();

                                id = response.id;
                            }
                            break;

                        case 'need_customerContact':
                            if (response.name && response.id) {
                                $select = parentSelect;
                                $select.append('<option value="' + response.id + '" selected="selected">'+response.name+'</option>');

                                id = response.id;
                            }
                            break;

                        case 'business_site':
                            if (response.name && response.id) {
                                $select =  $('#business_site');
                                $select.append('<option value="' + response.id + '" selected="selected">'+response.name+'</option>');
                                $('#btn_create_site').hide();

                                id = response.id;
                                modalEdit.showFormContact($('#btn_edit_site'), id, $('#business_contact'));
                            }
                            break;

                        case 'need_site':
                            if (response.name && response.id) {
                                $select = parentSelect;
                                $select.append('<option value="' + response.id + '" selected="selected">'+response.name+'</option>');
                                $('#btn_create_site').hide();

                                id = response.id;
                                modalEdit.showFormContact($('#btn_edit_site'), id, $('#need_customerContact'));
                            }
                            break;

                        case 'need_collaborater':
                            if (response.name && response.id) {
                                $select = parentSelect;
                                $select.parents('.row:first').find('.add_link').trigger('click');
                                let $lastSelect = $select.parents('.row:first').find('select.form-control').last();
                                setTimeout(function() {
                                    $lastSelect.append('<option value="' + response.id + '" selected="selected">'+response.name+'</option>');
                                    $lastSelect.val(response.id);
                                    $lastSelect.selectpicker("refresh");
                                }, 300);
                            }
                            break;

                        case 'site_interlocuteurs':
                        case 'customer_contacts':
                        case 'customer_potentiel_contacts':
                        case 'fournisseur_contacts':
                        case 'paper_calcul_contactCustomer':
                            $select = parentSelect;
                            let $table = $('#table_' + idSelect);

                            let fonction = response.fonction ? response.fonction : '';
                            let email  = response.email ? response.email : '';

                            $select.append('<option value="' + response.id + '" selected="selected">' + response.name + '</option>');
                            $table.find('tbody').append('<tr><td>'+ response.name +'</td><td>' + fonction  + '</td><td>' + response.phones + '</td><td>' + email + '</td>');
                            break;

                        case 'paper_calcul_collaborater':
                            if (response.name && response.id) {
                                $select = $('#paper_calcul_collaborater');
                                $select.append('<option value="' + response.id + '" selected="selected">'+response.name+'</option>');

                                id = response.id;
                            }
                            break;

                        case 'paper_calcul_customer':
                            if (response.name && response.id) {
                                $select = $('#paper_calcul_customer');
                                $select.append('<option value="' + response.id + '" selected="selected">'+response.name+'</option>');

                                id = response.id;
                            }
                            break;

                        default:
                            $select.find('option[value="'+id+'"]').html(response.name);
                    }

                    $select.selectpicker('refresh');
                } else {
                    response.error.forEach(function (index) {
                        pushToast('error', index);
                    });
                }
            });
        });
    });
};

/**
 * Ajax général.
 *
 * @param dataAjax
 * @param params
 * @param callbackSubmitForm
 */
ModalEditObject.prototype.ajax = function(dataAjax, params, callbackSubmitForm)
{
    var modalEdit = this;

    var url = dataAjax.url;
    var idForm = dataAjax.idForm;

    var spinner = '<div class="text-center"><img class="rounded" style="height: 4em" src="/img/spinner.gif" alt="spinner"></div>';

    this.$modalForm.find('.modal-body').html(spinner);

    if (url)
    {
        $.post(url , params)
            .done(function( data ) {

                modalEdit.$modalForm.find('.modal-body').html(data.content);

                if (modalEdit.$modalForm.find('.datepicker')) {
                   setTimeout(function () {

                       modalEdit.$modalForm.find('.datepicker').datepicker({
                           autoclose: true,
                           format: 'dd/mm/yyyy',
                           language: 'fr'
                       });
                   }, 300);
                }

                if (modalEdit.$modalForm.find('.selectpicker')) {
                    $('.selectpicker').selectpicker();
                }

                modalEdit.$modalForm.find('input[data-toggle="toggle"]').bootstrapToggle();

                modalEdit.$modalForm.find('#business_contact').on('change', function () {
                    var $selectContact = $(this);
                    var idContact = parseInt($(this).val());

                    modalEdit.changeValueFormContact($selectContact, idContact);
                });

                modalEdit.$modalForm.find('.select_show_modal').on('change', function () {
                    modalEdit.showButtonEdit($(this));
                });

                if (modalEdit.$modalForm.find('.bloc_form-contact').data('id')) {
                    $('.bloc_form-contact').show();
                    modalEdit.getContactCustomer(modalEdit.$modalForm.find('#business_customer').val(), modalEdit.$modalForm.find('#business_contact'));
                    // modalEdit.getContactCustomer(modalEdit.$modalForm.find('#need_customer').val(), modalEdit.$modalForm.find('#need_customerContact'));
                }

                if (modalEdit.$modalForm.find('#causerie_file_edit').length) {
                    new editPicture($('#causerie_file_edit').parents('.edit_picture_file'));
                }

                // modalEdit.$modalForm.find('#table').bootstrapTable();

                if (modalEdit.$modalForm.find('#form_suivi_mission').length) {
                    var today = new Date();
                    // modalEdit.$modalForm.find('#suivi_mission_business_suiviMissions___name___date').datepicker( 'setDate', today);
                    modalEdit.$modalForm.find('.radiosSuiviMission').radiosToSlider({animation: true});
                    modalEdit.$modalForm.find('.radiosSatisfactionGlobale').radiosToSlider({animation: true});
                }

                if (idForm) {
                    modalEdit.$modalForm.find('#'+idForm).submit(function(e) {
                        e.preventDefault();
                        callbackSubmitForm();
                    });
                }
            });
    }
};

/**
 * Retourne les informations pour l'ajax en fonction de l'élément.
 *
 * @param elem
 * @param id
 * @returns {{idForm: string, url: string}}
 */
ModalEditObject.prototype.getInfoAjax = function(elem, id, $btn)
{
    var data = {
        url : "/admin/collaborateurs/new",
        idForm : 'form_collaborater',
        params : { id: $btn.id },
        callback: function () {}
    };

    switch (elem) {
        case 'rexClient':
            data.url = "/rexClient/" + id + "/new";
            data.idForm = 'formRexClient';
            break;
        case 'business_customer':
        case 'bill_customer':
        case 'other_bill_customer':
        case 'need_customer':
            if ($btn && $btn.id == 'btn_create_customer') {
                data.url = "/admin/clients/nouveau";
            } else {
                data.url = "/admin/clients/"+id+"/edit";
            }

            data.idForm = 'form_customer';
            break;

        case 'business_site':
        case 'need_site':
            if ($btn && $btn.id == 'btn_create_site') {
                data.url = "/admin/sites/new";
            } else {
                data.url = "/admin/sites/"+id+"/edit";
            }

            data.idForm = 'form_site';
            break;

        case 'business_collaborater':
            data.url = "/admin/collaborateurs/"+id+"/edit";
            data.idForm = 'form_collaborater';
            break;

        case 'businessEngineers':
            data.url = "/admin/suivi_mission/"+id+"/affaire/" + $($btn).data('tab');
            data.idForm = 'form_suivi_mission';
            data.callback = function () {
                $('#modalAddSuiviMission').modal('hide');
                $tableSuiviMission.bootstrapTable('refresh');
            };
            break;

        case 'suiviMission':
            data.url = "/admin/suivi_mission/new";
            data.idForm = 'form_suivi_mission_add';
            break;

        case 'remonteeBusiness':
            data.url = "/admin/remontees_affaire/"+id+"/edit";
            data.idForm = 'form_remontee_business_edit';
            break;

        case 'audit':
            data.url = "/admin/audits/"+id+"/edit";
            data.idForm = 'form_audit_edit';
            break;

        case 'visitBusiness':
            data.url = "/admin/visites_chantier/"+id+"/edit";
            data.idForm = 'form_visit_business_edit';
            break;

        case 'business':
            data.url = "/admin/affaires/" + id + "/edit";
            data.idForm = 'form_business';
            break;

        case 'visitBusinessSecurite':
            data.url = "/admin/visites_chantier/"+id;
            break;

        case 'customer_potentiel_contacts':
        case 'site_interlocuteurs':
        case 'fournisseur_contacts':
            data.url = "/admin/contacts/new";
            data.idForm = 'form_contact';
            break;

        case 'need_customerContact':
        case 'paper_calcul_contactCustomer':
        case 'customer_contacts':
            data.url = "/admin/contacts/new/2";
            data.idForm = 'form_contact';
            break;

        case 'causerieSignature':
            data.url = "/admin/causeries/" + id + '/signatures';
            break;

        case 'pdpCheckPdps':
            data.url = "/admin/plans_de_preventions/" + id + '/checkPdps';
            break;

        case 'causerie':
            data.url = "/admin/causeries/" + id + "/edit";
            data.idForm = 'form_causerie_edit';
            break;

        case 'formationSecurity':
            data.url = "/admin/formations_security/" + id + '/edit';
            break;

        case 'rex':
            data.url = "/rex/" + id + "/new";
            data.idForm = 'form_rex';

            data.callback = function () {
                $('[id="'+elem+'"][data-id="' + id + '"]').html('<i class="fas fa-eye"></i> Voir le rex');
            };

            break;

        case 'paper_calcul_collaborater':
        case 'need_collaborater':
            data.url = "/admin/candidats/new";
            data.idForm = 'form_candidat';
            break;

        case 'paper_calcul_customer':
            data.url = "/admin/clients_potentiels/new";
            data.idForm = 'form_customer_potentiel';
            break;

        case 'need':
            data.url = "/admin/need/"+id+"/edit";
            data.idForm = 'form_need_edit';
            break;

        default:
            var rXVehicule= /^business_businessHasVehicules_[0-9a-f]_vehicule$/i;
            var MVehicule = rXVehicule.exec(elem);

            if (MVehicule) {
                var idVehicule = $('#' + MVehicule[0]).data('id');

                if (idVehicule) {
                    data.url = "/admin/vehicule/" + idVehicule + "/edit";
                    data.idForm = "form_vehicule";
                }
            }
    }


    return data;
};

/**
 * Récupère les contacts d'un client et affiche le form en dessous.
 *
 * @param idCustomer
 * @param $selectContact
 */
ModalEditObject.prototype.getContactCustomer = function(idCustomer, $selectContact)
{
    var modalEdit = this;
    var contacts = [];

    // if (modalEdit.$modalForm.is(':visible')) {
    //     $selectContact = modalEdit.$modalForm.find('#business_contact');
    // }

    $.post('/admin/clients/' + idCustomer + '/contacts' , { id: idCustomer })
        .done(function( data ) {
            $selectContact.empty()
            $selectContact.html('');
            $('.phoneContact').val('');
            $('.mailContact').val('');
            $('.functionContact').val('');

            data.forEach(function (contact) {
                contacts.push({
                    id: contact.id,
                    mail: contact.mail,
                    phone: contact.phones[0] !== undefined ? contact.phones[0].phone : null,
                    fonction: contact.fonction,
                });

                var idContact = modalEdit.$selectContact.parents('.bloc_form-contact').data('id');

                if(idContact && idContact == contact.id)
                {
                    $selectContact.append('<option value="'+contact.id+'" selected="selected">'+ contact.firstname + ' ' + contact.lastname +'</option>');
                } else {
                    $selectContact.append('<option value="'+contact.id+'">'+ contact.firstname + ' ' + contact.lastname +'</option>');
                }
            });
        });

        setTimeout(function () {
            modalEdit.contacts = contacts;

            if ($selectContact.val() !== '' && contacts.length) {
                modalEdit.changeValueFormContact($selectContact, contacts[contacts.length - 1].id);
            }
        }, 1000);
};

/**
 * Ajoute le bouton pour éditer dans le cas ou on a selectionné un élément dans une liste déroulante.
 *
 * @param $select
 */
ModalEditObject.prototype.showButtonEdit = function ($select) {
    var $btnEdit = $select.parents('.row:first').find('.btn_edit_item');
    var id = $select.val();

    if (id === '') {
        $btnEdit.hide();
        // $('.bloc_form-contact').hide();
        $('#bill_collaborater').html('');
        $('#bill_business').html('');
        $('#bill_collaborater').selectpicker('refresh');
        $('#bill_business').selectpicker('refresh');

        if ($select.attr('id') === 'business_customer' || $select.attr('id') === 'need_customer') {
            $('#btn_create_customer').show();
        } else if ($select.attr('id') === 'business_site' || $select.attr('id') === 'need_site') {
            $('#btn_create_site').show();
        }
    } else {
        $btnEdit.show();
        if ($select.attr('id') === 'business_customer') {
            this.showFormContact($btnEdit, id, $('#business_contact'));
            $('#btn_create_customer').hide();
        }
        else if ($select.attr('id') === 'business_site' || $select.attr('id') === 'need_site') {
            $('#btn_create_site').hide();
        }
    }
};

window.modalEditObject = ModalEditObject;


