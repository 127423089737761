'use strict';

// import $ from "jquery";

const CollectionHolder = function (classUl, config) {
    this.$collectionHolder = classUl;

    this.config = {
        align : config === undefined || config.align === undefined ? false : config.align,
        textBtn : config === undefined || config.textBtn === undefined ? 'Ajouter' : config.textBtn,
        initButtonDelete :  config === undefined || config.initButtonDelete === undefined ? true : config.initButtonDelete,
        colElem : config === undefined || config.colElem === undefined ? 'col-md-12' : config.colElem,
        collapse : config === undefined || config.collapse === undefined ? false : config.collapse,
        collapseTitle : config === undefined || config.collapseTitle === undefined ? 'Item' : config.collapseTitle,
        marginDelete : config === undefined || config.marginDelete === undefined ? false : config.marginDelete,
        callback : config === undefined || config.callback === undefined ? false : config.callback,
        callbackBeforeAdd : config === undefined || config.callbackBeforeAdd === undefined ? false : config.callbackBeforeAdd,
        callbackRemove : config === undefined || config.callbackRemove === undefined ? false : config.callbackRemove
    };

    if (this.$collectionHolder.length > 0)
    {
        console.info('init collectionHolder');
        this.init();
    }
};

CollectionHolder.prototype.init = function ()
{
    var collectionHolder = this;

    if (this.config.align)
    {
        this.$newLinkLi = $('<div class="col-md-5"></div>');
    } else {
        this.$newLinkLi = $('<div></div>');
    }

    if(this.$collectionHolder.find('.add_link').length === 0) {
        this.$addButton = this.createAddButton();
    }

    this.$newLinkLi.append(this.$addButton);
    this.$collectionHolder.append(this.$newLinkLi);

    if (this.config.collapse) {
        this.$collectionHolder.find("a.collapsed:last").trigger("click");
        this.$collectionHolder.find('.panel-body').each(function() {
            collectionHolder.addFormDeleteLink($(this));
        });
    } else {
        if (this.config.initButtonDelete)
        {
            this.$collectionHolder.find('li').not('li li').each(function() {
                if($(this).find('button.btn-danger').length === 0) {
                    collectionHolder.addFormDeleteLink($(this));
                }
            });
        }
    }

    this.$collectionHolder.data('index', this.$collectionHolder.find(':input').length);
};

CollectionHolder.prototype.createAddButton = function()
{
    var collectionHolder = this;

    var button = document.createElement('button');
    button.type = 'button';
    button.className = 'add_link btn btn-success btn-sm';
    button.style.marginBottom = '1em';

    var icon = document.createElement('i');
    icon.className = "fa fa-plus-circle";
    button.appendChild(icon);

    var textBtn = document.createTextNode(' ' + this.config.textBtn);
    button.appendChild(textBtn);

    button.addEventListener('click', function (event) {
        var success = true;

        if (collectionHolder.config.callbackBeforeAdd) {
            var prototype = new DOMParser().parseFromString(collectionHolder.$collectionHolder.data('prototype'), 'text/html');
            var result = collectionHolder.config.callbackBeforeAdd(event, prototype);

            if (typeof result === 'object') {
                success = result.success;

                if (result.prototype) {
                    collectionHolder.$collectionHolder.data('prototype', result.prototype.documentElement.innerHTML);
                }
            } else {
                success = result;
            }
        }

        if (success) {
            collectionHolder.lengthItem = collectionHolder.lengthItem + 1;
            collectionHolder.addForm();
        }
    });

    return $(button);
};

CollectionHolder.prototype.addFormDeleteLink = function($formLi)
{
    var collectionHolder = this;
    var $removeFormButton = $('<button type="button" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i> Supprimer</button>');

    if (collectionHolder.config.marginDelete) {
        $removeFormButton.css('margin-top', '2em');
    }

    $formLi.append($removeFormButton);

    $removeFormButton.on('click', function() {
        collectionHolder.lengthItem = collectionHolder.lengthItem - 1;

        var id = $formLi.attr('id');

        if (collectionHolder.config.collapse) {
            $formLi.parents('.panel').remove();
        }  else {
            $formLi.remove();
        }

        if (collectionHolder.config.callbackRemove) {
            collectionHolder.config.callbackRemove(id);
        }
    });
};

CollectionHolder.prototype.addForm = function()
{
    var prototype = this.$collectionHolder.data('prototype');
    var index = this.$collectionHolder.data('index');
    var newForm = prototype;

    newForm = newForm.replace(/__name__/g, index);

    this.$collectionHolder.data('index', index + 1);

    var $newFormLi = null;

    if (this.config.collapse) {
        $newFormLi = this.createPanel();
        $newFormLi.find('.panel-body').html(newForm);
        this.addFormDeleteLink($newFormLi.find('.panel-body'));
        $newFormLi.find('.collapse').collapse();
    } else {
        $newFormLi = this.createLi();
        $newFormLi.append(newForm);
        this.addFormDeleteLink($newFormLi);
    }

    $newFormLi.find('.datepicker').datepicker({
        autoclose: true,
        format: 'dd/mm/yyyy',
        language: 'fr'
    });

    $newFormLi.find('.datetimepicker').datetimepicker({
        locale: 'fr'
    });

    $newFormLi.find('input').iCheck({
        checkboxClass: 'icheckbox_flat-red',
        radioClass: 'iradio_square',
        increaseArea: '20%' // optional
    });

    $newFormLi.find('.selectpicker').selectpicker();


    this.$newLinkLi.before($newFormLi);

    if (this.config.callback) {
        this.config.callback(this.$collectionHolder);
    }
};

CollectionHolder.prototype.createPanel = function()
{
    var numPanel = this.$collectionHolder.find('.panel-heading').length + 1;

    var panel = document.createElement('div');
    panel.className = 'panel panel-default';
    panel.style.marginBottom = '1em';

    var divPanelHeading = document.createElement('div');
    divPanelHeading.className = 'panel-heading';
    divPanelHeading.setAttribute('role', 'tab');
    divPanelHeading.id = 'heading' + numPanel;

    var titlePanel = document.createElement('h4');
    titlePanel.className = "panel-title";

    var linkTitle = document.createElement('a');
    linkTitle.className = "collapsed";
    linkTitle.setAttribute('role', 'button');
    linkTitle.href = "#collapse" + numPanel;
    linkTitle.setAttribute('data-toggle', 'collapse');
    linkTitle.setAttribute('data-parent', '#accordion');
    linkTitle.setAttribute('aria-expanded', 'false');
    linkTitle.setAttribute('aria-controls', 'collapse' + numPanel);

    var textTitle = document.createTextNode(this.config.collapseTitle + ' ' + numPanel);
    linkTitle.appendChild(textTitle);

    var collapse = document.createElement('div');
    collapse.id = "collapse" + numPanel;
    collapse.className = "panel-collapse collapse";
    collapse.setAttribute('role', 'tabpanel');
    collapse.setAttribute('aria-labelledby', 'heading' + numPanel);
    linkTitle.setAttribute('aria-expanded', 'false');

    var panelBody = document.createElement('div');
    panelBody.className = "panel-body";
    collapse.appendChild(panelBody);

    titlePanel.appendChild(linkTitle);
    divPanelHeading.appendChild(titlePanel);
    panel.appendChild(divPanelHeading);
    panel.appendChild(collapse);

    return $(panel);
};

CollectionHolder.prototype.createLi = function()
{
    var li = document.createElement('li');

    if (this.config.align)
    {
        li.className = 'list-group-item ' + this.config.colElem;
    } else {
        li.className = 'list-group-item';
    }

    li.style.marginBottom = '1em';
    li.style.zIndex = 1;

    return $(li);
};

window.collectionHolder = CollectionHolder;
