'use strict';

import $ from "jquery";
let moment = require('moment');

$.fn.TabDate = function (config) {
    this.init = function () {
        var tabDate = this;

        if (!this.$inputDate.hasClass('datepicker')) {
            this.$inputDate.addClass('datepicker');
        }

        this.load();

        this.$inputDate.on('change', function () {
            tabDate.load();

            if (tabDate.config.updateDate) {
                tabDate.config.updateDate(tabDate.dateFormatAjax(tabDate.dateSelect));
            }
        });
    };

    this.load = function () {
        this.initDate();
        this.createTab();
        this.loadAjaxContent();
    };

    this.initDate = function () {
        if(!this.dateSelect) {
            this.$inputDate.datepicker('setDate', 'today');
            this.dateSelect = this.$inputDate.datepicker( 'getUTCDate' );
        } else {
            this.dateSelect = this.$inputDate.datepicker('getDate');
        }
    };

    this.createTab = function () {
        var tabDate = this;
        var iDate = this.gestDateFirstDayWeek(this.dateSelect);

        var navTabsCustom = document.createElement('div');
        navTabsCustom.className = "nav-tabs-custom tab-danger";
        
        var ulNav = document.createElement('ul');
        ulNav.className = "nav nav-tabs";

        var tabContent = document.createElement('div');
        tabContent.className = 'tab-content';
        
        for (var i = 0; i < 7; i++)
        {
            var liToogle = document.createElement('li');

            var aToogle = document.createElement('a');
            aToogle.href = "#tab_date_" + i;
            aToogle.setAttribute('data-date', this.dateFormatAjax(iDate));
            aToogle.setAttribute('data-toogle', 'tab');
            var textAToogle = document.createTextNode(this.daysString[i] + ' ' + this.pad(iDate.getDate()) + '/' + this.pad(iDate.getMonth()));

            aToogle.appendChild(textAToogle);
            liToogle.appendChild(aToogle);
            ulNav.appendChild(liToogle);

            var tabPane = document.createElement('div');
            tabPane.className = 'tab-pane';
            tabPane.id = 'tab_date_' + i;
            var rowTabPane = document.createElement('div');
            rowTabPane.className = 'row contentTab';

            var contentRowTabPane = document.createElement('div');
            contentRowTabPane.className = 'text-center';

            var spinner = document.createElement('img');
            spinner.src = '/img/spinner.gif';
            spinner.className = 'rounded';
            spinner.style.height = '3em';

            contentRowTabPane.appendChild(spinner);
            rowTabPane.appendChild(contentRowTabPane);
            tabPane.appendChild(rowTabPane);
            tabContent.appendChild(tabPane);

            // On active l'onglet de la date selectionné.
            if (i === (this.dateSelect.getDay() - 1))
            {
                $(navTabsCustom).tabs();
                $(aToogle).tab('show');
            }

            iDate.setDate(iDate.getDate() + 1);
        }

        navTabsCustom.appendChild(ulNav);
        navTabsCustom.appendChild(tabContent);

        this.$container.html(navTabsCustom);
        $(navTabsCustom).tabs();

        var idActive = $(navTabsCustom).find('li.active').find('a').attr('href');
        var $liActive = $(navTabsCustom).find(idActive);
        $liActive.addClass('active');
        this.$currentTab = $liActive.find('.contentTab');

        $(navTabsCustom).find(".nav-tabs").find('a').click(function(){
            $(this).tab('show');
            var idTab = $(this).attr('href');
            tabDate.$currentTab = $(idTab).find('.contentTab');

            if ($(this).data('date') !== undefined)
            {
                var dateCurrent = new Date($(this).data('date'));

                if (dateCurrent) {
                    tabDate.dateSelect = dateCurrent;
                }
            }

            tabDate.loadAjaxContent();
        });
    };

    this.loadAjaxContent = function () {
        var tabDate = this;

        $.ajax({
            method: "POST",
            url: this.config.ajax,
            data: { date: this.dateFormatAjax(this.dateSelect)}
        })
        .done(function( data ) {
            tabDate.$currentTab.html(data.content);

            if (tabDate.config.afterAjax) {
                tabDate.config.afterAjax(data);
            }
        });
    };

    /**
     * Retourne la date du premier jour de la semaine.
     * @param dateTarget
     */
    this.gestDateFirstDayWeek = function (dateTarget) {
        var dateFirstWeek = new Date();
        return new Date(dateFirstWeek.setDate(dateTarget.getDate() - (dateTarget.getDay() - 1)));
    };

    this.pad = function (n) {
        return n<10 ? '0'+n : n
    };

    /**
     * Retourne la date dans un format valid pour l'envoie en ajax
     * @param date
     * @returns {string}
     */
    this.dateFormatAjax = function (date) {
       return moment(date).format("YYYY-MM-DD")
    };

    this.$inputDate = $(this);

    if (this.$inputDate.length) {
        this.$container = $('#tabDate');
        this.dateSelect = this.$inputDate.datepicker( 'getUTCDate' );
        this.daysString = ['Lundi', 'Mardi', 'Mecredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
        this.$currentTab = null;

        this.config = {
            ajax : config.ajax.length ? config.ajax : null,
            afterAjax: config.afterAjax.length ? config.afterAjax : null,
            updateDate: config.updateDate.length ? config.updateDate : null,
        };

        if (!this.config.ajax)
        {
            console.error('Erreur module TabDate : L\'url de l\'ajax n\'est pas renseigné.');
        } else {
            this.init();
        }
    } else {
        console.error('Erreur module TabDate : Le container n\'a pas été trouvé.');
    }
};
