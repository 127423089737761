'use strict';

const EditPicture = function (elem) {
    this.$editPicture = elem;
    this.init();
};

EditPicture.prototype.init = function() {
    var editPicture = this;
    var script = this.$editPicture.find('script').html();

    if (!this.$editPicture.find('button').length) {
        this.$editPicture.html(this.$editPicture.html().replace("input", "button") + '</button>');

        this.$editPicture.append('<script>'+script+' function setValue(value, element_id) {\n' +
            'if ($(".test").length > 0) { $(".bloc-file").attr("href", value) } else {' +
            '$(document.querySelector(\'[data-type="elfinder-input-field"]\' + (element_id ? \'[id="\'+ element_id +\'"]\': \'\'))).parent().find("a").remove(); ' +
            '$(document.querySelector(\'[data-type="elfinder-input-field"]\' + (element_id ? \'[id="\'+ element_id +\'"]\': \'\'))).parent().prepend("<a href=\'"+value+"\' target=\'_blank\' class=\'bloc-file\'> ' +
            '<i class=\'fas fa-file\'></i><br/><p>Voir</p></a>");\n' +
            '} document.querySelector(\'[data-type="elfinder-input-field"]\' + (element_id ? \'[id="\'+ element_id +\'"]\': \'\')).value = value;}</script>');

        var $buttonElFinder =  this.$editPicture.find('button');
        this.$editPicture.prepend('<input name="'+$buttonElFinder.attr('name')+'" value="'+$buttonElFinder.attr('value')+'" ' +
            'id="'+$buttonElFinder.attr('id')+'" data-type="elfinder-input-field" class="inputPicture" type="hidden">');
        $buttonElFinder.addClass('btn btn-default');

        $buttonElFinder.attr('type', 'button');

        $buttonElFinder.on('click', function (e) {
            e.preventDefault();
        });

        if (!$buttonElFinder.attr('disabled')) {
            $buttonElFinder.html("<i class='fas fa-pencil-alt'></i>");
            this.$editPicture.append('<button class="btn btn-default remove_file"><i class="fas fa-trash"></i></button>');

            this.$editPicture.find('.remove_file').on('click', function (e) {
                e.preventDefault();
                editPicture.$editPicture.find(".bloc-file").remove();
                editPicture.$editPicture.find('.inputPicture').val('');
            });
        } else {
            $buttonElFinder.remove();
        }

    }
};

window.editPicture = EditPicture;


